<template>
<v-card class="ma-1 mb-3">
  <v-simple-table v-if="days.length > 0" dense>
    <tbody>
      <tr>
        <th>Date</th>
        <td class="text-right">Count</td>
        <td class="text-right">Rate</td>
        <template v-for="(day,d) in days">
          <td class="text-right" :key="d">{{$moment(day.timestamp).format('DD/MM')}}</td>
        </template>
      </tr>
      <tr class="border-bottom-medium">
        <th>Day</th>
        <td class="text-right">{{days.length}}</td>
        <td class="text-right">-</td>
        <template v-for="(day,d) in days">
          <td class="text-right" :key="d">{{d+1}}</td>
        </template>
      </tr>
      <template v-for="(j,i) in ['activities','submissions','questions','corrects',]">
        <tr v-if="showSubmission && counts[j]" :key="i">
          <th>{{$_ucfirst(j)}}</th>
          <td class="text-right">{{counts[j][days.length-1]}}</td>
          <td class="text-right">-</td>
          <template v-for="(day,d) in days">
            <td class="text-right" :key="d">{{counts[j][d]}}</td>
          </template>
        </tr>
      </template>
      <tr v-if="showSubmission" class="border-bottom-medium">
        <th>Accuracy</th>
        <td class="text-right">-</td>
        <td class="text-right">-</td>
        <template v-for="(day,d) in days">
          <td class="text-right" :key="d">{{(counts.corrects[d]/counts.questions[d]*100).toFixed(1)}}%</td>
        </template>
      </tr>
      <template v-for="(m,i) in milestones">
        <tr :class="i == milestones.length-1 ?' border-bottom-medium' : ''" :key="`milestone${i}`">
          <th>
            <span>{{$_ucfirst(m)}} </span>
            <v-tooltip right v-if="tips[m]">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  small 
                  v-bind="attrs"
                  v-on="on"
                >mdi-information</v-icon>
              </template>
              <span>{{tips[m]}}</span>
            </v-tooltip>
          </th>
          <td class="text-right">{{counts[m][days.length-1]}}</td>
          <td class="text-right">{{(counts[m][days.length-1]/counts.registered[days.length-1]*100).toFixed(1)}}%</td>
          <template v-for="(day,d) in days">
            <td v-if="counts[m]" class="text-right" :key="d">{{counts[m][d]}}</td>
          </template>
        </tr>
      </template>
       <template v-for="(m,i) in active">
        <tr :key="`active${i}`">
          <th>
            <span>{{m.toUpperCase()}} </span>
            <v-tooltip right v-if="tips[m]">
              <template v-slot:activator="{ on, attrs }">
                <v-icon 
                  small 
                  v-bind="attrs"
                  v-on="on"
                >mdi-information</v-icon>
              </template>
              <span>{{tips[m]}}</span>
            </v-tooltip>
          </th>
          <td class="text-right">{{totalActive[m]}}</td>
          <td class="text-right">-</td>
          <template v-for="(day,d) in days">
            <td v-if="counts[m]" class="text-right" :key="d">{{counts[m][d]}}</td>
          </template>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
  <div v-else class="pa-3 text-center text-caption">No data available.</div>
</v-card>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      data:{
        type:Object,
        default:null,
      },
      showSubmission:{
        type:Boolean,
        default:true,
      },
      activity:{
        type:Array,
        default:null
      }
    },
    data: () => ({
      days:[],
      milestones:[
        'registered',
        'activated',
        'joined',
        'played',
        'tried',
        'completed',
        'mastered',
      ],
      active:[
      'dau',
      'dsu'
      ],
      totalActive:{
        dau:0,
        dsu:0
      },
      counts:{
        submissions:{},
        questions:{},
        corrects:{},
        activities:{}
      },
      tips:{
        'registered':'Student is registered.',
        'activated':'Student has activated the account.',
        'played':'Student has at least one submission.',
        'tried':'Student has at least one submission for each topics',
        'completed':'Student has obtained at least one star for each topics',
        'mastered':'Student has obtained 3 stars for each topics',
        'dau':'Daily active user',
        'dsu':'Daily submission user'
      },
    }),
    created() {
      this.generateDays()
      this.processSubmissions()
      this.processMilestones()
       this.processActive()
    },
    mounted() {
      //
    },
    methods: {
      generateDays() {
        var event = this.data.event
        var duration = this.$moment(event.timestampEnd).startOf('day').diff(this.$moment(event.timestampStart).startOf('day'),'days') + 1
        for(var i=0;i<duration;i++) {
          this.days.push({
            i:i,
            timestamp:this.$moment(this.data.event.timestampStart).startOf('day').add(i,'days').toISOString(),
          })
        }
      },
      processSubmissions() {
        for(var day of this.days) {
          var filtered = this.$_.filter(this.data.submissions,(o)=>{ 
            return (this.$moment(day.timestamp).add(1,'days').diff(this.$moment(o.timestampCreated)) > 0) 
          })
            var filteredActivity = this.$_.filter(this.data.activity,(o)=>{ 
            return (this.$moment(day.timestamp).add(1,'days').diff(this.$moment(o.timestampCreated)) > 0) 
          })
 
          this.counts.submissions[day.i] = filtered.length
          this.counts.activities[day.i] = filteredActivity.length
          this.counts.questions[day.i] = this.$_.sumBy(filtered,(o)=>{ return o.countQuestion })
          this.counts.corrects[day.i] = this.$_.sumBy(filtered,(o)=>{ return o.countCorrect })
          
        }
      },
      processMilestones() {
        for(var milestone of this.milestones) {
          this.counts[milestone] = {}
          for(var day of this.days) {
            var filtered = this.$_.filter(this.data.students,(o)=>{ 
              if(!o.milestones[milestone]) return false
              return (this.$moment(day.timestamp).add(1,'days').diff(this.$moment(o.milestones[milestone])) > 0) 
            })
            this.counts[milestone][day.i] = filtered.length
          }
        }
      },
       processActive() {
        for(var milestone of this.active) {
          this.counts[milestone] = {}
          
         
          for(var day of this.days) {
            var count = 0
             for(var student of this.data.students) {
            var filtered = this.$_.filter(student.milestones[milestone],(o)=>{ 
              
              if(!o) return false
              return (this.$moment(day.timestamp).format('YYYY/MM/DD') ==  o) 
            })
            count = count + filtered.length
            this.totalActive[milestone] = filtered.length +  this.totalActive[milestone]
           
          }
           this.counts[milestone][day.i] = count
          }
          
        }
      },
    }
  }
</script>
<style>
.border-bottom-medium > td, .border-bottom-medium > th {
  border-bottom: solid medium black !important;
}
</style>